<template>
  <div>
    <div class="page-banner">
      <div
        class="container"
        style="background-image:url('https://downloads.acs.com.hk/page-headers/951_2017_sales_enquiry.jpg?5fcee43c5a7a1');"
      >
        <div class="wrapper">
          <div class="content">
            <h1>
              <a class="title">Clinic Enquiry</a>
            </h1>
          </div>
        </div>
      </div>
    </div>

    <div class="container technical-enquiry">
      <div class="row">
        <div class="col-md-10 offset-md-1">
          <div class="page-content">
            <form action="">
              <h5>Contact Information</h5>
              <div class="form-group row">
                <label class="col-sm-3 col-form-label"
                  >Email<span class="acs-red">*</span>:</label
                >
                <div class="col-sm-9">
                  <input class="form-control input-sm" name="email" required />
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-3 col-form-label"
                  >Name<span class="acs-red">*</span>:</label
                >
                <div class="col-sm-9">
                  <input class="form-control input-sm" name="email" required />
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-3 col-form-label"
                  >Company Name<span class="acs-red">*</span>:</label
                >
                <div class="col-sm-9">
                  <input class="form-control input-sm" name="email" required />
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-3 col-form-label"
                  >Number of people<span class="acs-red">*</span>:</label
                >
                <div class="col-sm-9">
                  <input class="form-control input-sm" name="email" required />
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-3 col-form-label"
                  >Registered Name List<span class="acs-red">*</span>:</label
                >
                <div class="col-sm-9">
                  <input class="form-control input-sm" name="email" required />
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-3 col-form-label"
                  >Phone number<span class="acs-red">*</span>:</label
                >
                <div class="col-sm-9">
                  <input class="form-control input-sm" name="email" required />
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-3 col-form-label"
                  >State<span class="acs-red">*</span>:</label
                >
                <div class="col-sm-9">
                  <select
                    class="form-control input-sm"
                    name="country"
                    required=""
                    data-parsley-required-message="Please select your country."
                  >
                    <option value="Johor">Johor</option
                    ><option value="Kedah">Kedah</option
                    ><option value="Kelantan">Kelantan</option
                    ><option value="Melaka">Melaka</option
                    ><option value="Negeri Sembilan">Negeri Sembilan</option
                    ><option value="Pahang">Pahang</option
                    ><option value="Perak">Perak</option
                    ><option value="Perlis">Perlis</option
                    ><option value="Pulau Pinang">Pulau Pinang</option
                    ><option value="Sabah">Sabah</option
                    ><option value="Sarawak">Sarawak</option
                    ><option value="Selangor">Selangor</option
                    ><option value="Terengganu">Terengganu</option>
                  </select>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-3 col-form-label"
                  >Solution/Software<span class="acs-red">*</span>:</label
                >
                <div class="col-sm-9">
                  <input class="form-control input-sm" name="email" required />
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-3 col-form-label"
                  >Question / Error Encountered<span class="acs-red">*</span
                  >:</label
                >
                <div class="col-sm-9">
                  <input class="form-control input-sm" name="email" required />
                </div>
              </div>

              <div class="form-group row">
                <div class="offset-sm-3 col-sm-9">
                  <div id="container">
                    <span id="screenshots">
                      <i class="ion-android-attach"></i>
                      <span
                        >Attach Screenshots of Error Encountered (1MB
                        max.)</span
                      >
                    </span>
                  </div>
                  <ul id="attachments"></ul>
                  <div id="attachments-fields"></div>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-sm-3 col-form-label"
                  >Event Date & Time<span class="acs-red">*</span>:</label
                >
                <div class="col-sm-9">
                  <select
                    class="form-control input-sm"
                    name="country"
                    required=""
                    data-parsley-required-message="Please select your country."
                  >
                    <option value="Selangor"
                      >Date: 11/12/2020(Friday), Time : 2.00pm-3.00pm</option
                    ><option value="Terengganu"
                      >Date: 18/12/2020(Friday), Time : 2.00pm-3.00pm</option
                    >
                  </select>
                </div>
              </div>

              <div class="form-group row">
                <div class="offset-sm-3 col-sm-9">
                  <p>
                    <small class="acs-red">* Required field</small>
                  </p>
                  <button type="submit" class="btn btn-outline-primary">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Clinic"
};
</script>
